@import url('https://fonts.googleapis.com/css2?family=Noto Sans Display:wght@400;700&display=swap');
@import '../fonts/MEK-Mono.css';
@import '../fonts/Pixel_Bit_Adv.css';

:root,
:host,
.sl-theme-light {
  --sl-font-sans: 'Noto Sans Display';
  --sl-input-font-family: 'Noto Sans Display';
}

body {
  font-family: var(--sl-font-sans);
  line-height: 1.6em;
  margin: 0;
  background: white;
}

h1 {
  font-family: "Pixel Bit Advanced";
  padding: 70px 0 20px 0;
  text-align: center;
  color: #344;
}

a {
  text-decoration: none;
  color: inherit;
}

img.emoji {
  height: 1.4em;
  margin: 0 .05em 0 .1em;
  vertical-align: -0.3em;
}

#prose {
  border-radius: var(--sl-input-border-radius-medium);
  font-size: var(--sl-input-font-size-medium);
  background-color: var(--sl-input-background-color);
  border: solid var(--sl-input-border-width) var(--sl-input-border-color);
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-family: var(--sl-input-font-family);
  font-weight: var(--sl-input-font-weight);
  line-height: var(--sl-line-height-normal);
  letter-spacing: var(--sl-input-letter-spacing);
  vertical-align: middle;
  transition: var(--sl-transition-fast) color, var(--sl-transition-fast) border, var(--sl-transition-fast) box-shadow, var(--sl-transition-fast) background-color;
  cursor: text;

  & > div {
    border: none;
    width: 100%;
    min-height: 7em;
    padding: 0.5em var(--sl-input-spacing-medium);

    a {
      color: var(--sl-color-primary-600);
      font-weight: bold;
    }
  }
}

#emoji-anchor {
  position: relative;

  em-emoji-picker {
    position: absolute;
    left: -40px;
    z-index: 2;
  }
}

#downbads {
  max-width: 800px;
  width: 100%;
  margin: auto;
  position: relative;
}

#page {
  padding-top: 30px;

  &.narrow {
    width: 70%;
  }

  > .narrow {
    width: 70%;
    margin: 0 auto;
  }
}

.wallet {
  position: absolute;
  right: 0;
  margin: 10px;
  z-index: 2;
}

.banner-side, .banner-top {
  display: grid;
  grid-template-columns: auto auto 200px;
  grid-template-rows: 200px auto auto auto;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.banner-side {
  > .h-card {
    padding-top: 30px;
    grid-area: 1 / 1 / 2 / 3;
    grid-template-columns: 10rem auto;
    grid-template-rows: auto 60px;
    display: grid;
    grid-column-gap: 20px;

    > .avatar {
      position: relative;
      grid-area: 1 / 1 / 3 / 2;
    }

    > .bio {
      grid-area: 1 / 2 / 2 / 3;
    }

    > .community {
      grid-area: 2 / 2 / 3 / 3;

      > .line {
        display: inline-flex;
        font-family: 'MEK-Mono';
        flex-wrap: nowrap;
      }
    }
  }

  > .main {
    grid-area: 2 / 1 / 4 / 3;
  }

  > .sidebar {
    grid-area: 1 / 3 / 4 / 4;
  }
}

.banner-top {
  > .banner {
    grid-area: 1 / 1 / 3 / 3;

    img {
      width: 100%;
    }
  }

  > .h-card {
    padding-top: 70px;
    padding-left: 20px;
    grid-area: 1 / 3 / 3 / 4;
  }

  > .main {
    grid-area: 2 / 1 / 5 / 3;
  }

  > .sidebar {
    grid-area: 3 / 3 / 4 / 4;
    padding: 20px;
  }
}

.h-card {
  sl-avatar {
    --size: 10rem;
  }

  > .bio {
    h2 {
      font-size: 1.1em;
      text-wrap: nowrap;
    }
  }

  > .community {
    color: #999;
    font-size: 0.9em;
    width: 100%;

    a {
      color: #999;
      display: inline-block;
      padding-right: 8px;
    }

    sl-icon {
      padding-right: 3px;
    }

    > p {
      text-wrap: nowrap;
      margin: 4px 0;
    }
  }
}

ol.timeline {
  list-style: none;
  margin: 0;
  padding: 0;
}
  
.main sl-tab {
  line-height: 1em;
}

.user-post {
  display: grid;
  grid-template-columns: 3.6rem auto;
  grid-column-gap: 10px;
  grid-row-gap: 4px;
  margin: 20px 10px;

  & > sl-avatar   {
    grid-area: 1 / 1 / 3 / 2;
    --size: 3.6rem;
    cursor: pointer;
  }
  & > div.title   { grid-area: 1 / 2 / 2 / 3; }

  div.nametag {
    font-weight: bold;

    & > a {
      color: black;
    }
  }

  div.username, div.age, div.published {
    font-family: 'MEK-Mono';
    color: #666;
    font-size: 1.1em;
    
    a {
      color: #666;
    }
  }

  div.content {
    & > p:first-child {
      margin-top: 0;
    }

    & > p:last-child {
      margin-bottom: 0;
    }
  }
}

.brief-post {
  & > div.content {
    grid-area: 2 / 2 / 3 / 3;

    .content-group:first-child p:first-child {
      margin-top: 0.2em;
    }
  }

  div.title {
    display: flex;
    text-wrap: nowrap;
    column-gap: 8px;
  }
}

.full-post {
  & > div.content { grid-area: 2 / 1 / 3 / 3; }
}

.editor {
  display: grid;
  margin: 0 20px;
  grid-template-columns: 56px auto 80px;
  grid-template-rows: auto;
  gap: 8px;

  & > sl-avatar {
    grid-row: 2;
  }
  & > sl-select {
    grid-column: 2;
    justify-self: left;
  }
  & > sl-textarea, & > div#prose {
    grid-column: 2 / span 2;
  }
  & > .content-group {
    grid-column: 2 / span 2;
  }
  & > .info {
    grid-column: 2;

    & > button {
      color: var(--sl-color-primary-600);
      font-weight: bold;
      background: none;
      border: none;
      padding: 8px 4px;
      vertical-align: top;
      cursor: pointer;
    }
  }
  & > .finish {
    grid-column: 3;
    justify-self: end;
    display: inline-flex;
    flex-wrap: nowrap;

    sl-progress-ring {
      --size: 32px;
      margin: 0 12px;

      &.overflow {
        --indicator-color: red;
      }
    }
  }

  sl-button.thin::part(base) {
    --sl-input-height-medium: 24px;
  }
}

.buttons {
  padding-bottom: 60px;

  > sl-button {
    float: right;
    padding-left: 10px;
  }
}

.sidebar {
  > sl-button {
    width: 100%;
    padding-bottom: 10px;
  }

  img {
    width: 100%;
    min-height: 100px;
  }
}

.content-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 4px;
  overflow: hidden;
  max-width: 100%;
  max-height: 800px;

  > .content-image {
    position: relative;
    font-size: 20px;

    > sl-icon-button {
      position: absolute;
      right: 0;
    }

    > img {
      width: 100%;
      border-radius: 8px;
    }

    &:nth-child(1) {
      &:nth-last-child(2n+1) {
        grid-column: 1 / span 2;
      }
    }
  }

  > .content-text {
    grid-column: 1 / span 2;

    a {
      font-weight: bold;
      color: #079;
    }
  }

  > .content-video {
    grid-column: 1 / span 2;
    position: relative;

    > sl-icon-button {
      position: absolute;
      right: 0;
      z-index: 1;
    }

    > video {
      width: 100%;
      border-radius: 8px;
    }
  }
}

//
// Onboarding styles
//
div.select-avatar {
  display: block;
}

div.avatar {
  max-width: 172px;
  padding: 0 10px 20px 10px;
}

div.image-current {
  & > img {
    border: solid 4px #F09 !important;
  }
}

div.image-select {
  position: relative;
  float: left;

  & > p {
    position: absolute;
    right: 6px;
    color: white;
    font-weight: bold;
    margin: 4px 0;
    padding: 2px 6px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  & > img {
    width: 100%;
    border: solid 4px #eee;

    &:hover {
      border: solid 4px #509;
      cursor: pointer;
    }
  }
}

.welcome {
  li {
    list-style-type: none;
    position: relative;
  }

  li::before {
    content: '■';
    position: absolute;
    top: -0.1em;
    left: -1.2em;
    font-size: 1em;
    color: #f09;
  }
}
